import React from "react";
import { Grid } from "@material-ui/core";
import styles from "./roadmap.module.scss";
import Bg from "../../Assets/RoadmapWave.svg";

function Roadmap() {
  return (
    <Grid container xs={12} className={styles.RoadmapMain} id="Roadmap">
      <img src={Bg} className={styles.bgImg} />
      <Grid item xs={12} style={{ zIndex: 999, marginBottom: 70 }}>
        <h1 className={styles.heading}> Road Map</h1>
      </Grid>
      <div className="px1280above" style={{ zIndex: 999 }}>
        <Grid
          item
          container
          xs={11}
          direction="row"
          justify="center"
            style={{ margin: "auto" }}
            className={styles.bodyTImeLine}
        >
          <Grid
            item
            container
            xl={2}
            lg={2}
            md={12}
            sm={12}
            xs={12}
            direction="row"
            style={{ margin: "auto" }}
          >
            <Grid item xs={12} style={{ zIndex: 999 }}>
            <h1 className={styles.headerRoadmap}> - Bscience Launch </h1>
              <h1 className={styles.headerRoadmap}> - Ambassador Program</h1>

              <p className={styles.mainText}>
                {" "}
                5% of $BCSI will be allocated
                to our “Ambassadors” through staking. A YFSI Ambassador by
                definition is any person willing to stake a minimum of 50 of
                their YFSI tokens.
              </p>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "30px" }}>
              <p className={styles.pCircle}> 2021 </p>
              <p className={styles.pText}> Q1 </p>
            </Grid>
          </Grid>
          <div className="road_map_dot one" />
          <Grid
            item
            container
            xl={2}
            lg={2}
            md={12}
            sm={12}
            xs={12}
            direction="row"
            style={{ margin: "auto" }}
          >
            <Grid item xs={11}>
              <p className={styles.pCircle}> 2021 </p>
              <p className={styles.pText}> Q2 </p>
            </Grid>
            <Grid item xs={11} style={{ marginTop: "30px" }}>
              <h1 className={styles.headerRoadmap}> - BCSI Presale</h1>
              <h1 className={styles.headerRoadmap}> - Liquidity Mining & Staking</h1>
              <p className={styles.mainText}>
                {" "}
                <br />
                <br />
              </p>
            </Grid>
          </Grid>
          <div className="road_map_dot two" />
          <Grid
            item
            container
            xl={2}
            lg={2}
            md={12}
            sm={12}
            xs={12}
            direction="row"
            style={{ margin: "auto" }}
          >
            <Grid item xs={12}>
            <h1 className={styles.headerRoadmap}> - Governance</h1>
              <h1 className={styles.headerRoadmap}> - NFT Marketplace</h1>
              <p className={styles.mainText}>
                {" "}
                <br />
                <br />
              </p>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "30px" }}>
              <p className={styles.pCircle}> 2021 </p>
              <p className={styles.pText}> Q2 </p>
            </Grid>
          </Grid>
          <div className="road_map_dot three" />
          <Grid
            item
            container
            xl={2}
            lg={2}
            md={12}
            sm={12}
            xs={12}
            direction="row"
            style={{ margin: "auto" }}
          >
            <Grid item xs={12}>
              <p className={styles.pCircle}> 2021 </p>
              <p className={styles.pText}> Q3 </p>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "30px" }}>
            <h1 className={styles.headerRoadmap}> - Bridge</h1>
            <h1 className={styles.headerRoadmap}> - NFT Launchpad & NFT Mining</h1>
              <p className={styles.mainText}>
                {" "}
                <br />
                <br /> <br />
                <br />
              </p>
            </Grid>
          </Grid>
          <div className="road_map_dot four" />
          <Grid
            item
            container
            xl={2}
            lg={2}
            md={12}
            sm={12}
            xs={12}
            direction="row"
            style={{ margin: "auto" }}
          >
            <Grid item xs={12}>
              <h1 className={styles.headerRoadmap}>
                {" "}
                - Automated Trading Tool
              </h1>
              <p className={styles.mainText}>
                {" "}
                <br />
                <br /> <br />
                <br />
              </p>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "30px" }}>
              <p className={styles.pCircle}> 2021 </p>
              <p className={styles.pText}> Q4 </p>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className="px1280" style={{ zIndex: 999 }}>
        <Grid
          item
          container
          xs={12}
          direction="row"
          justify="space-around"
          className={styles.bodyTImeLine}
        >
          <Grid
            item
            container
            xl={2}
            lg={2}
            md={12}
            sm={12}
            xs={12}
            direction="row"
            style={{ margin: "auto", marginBottom: 100 }}
          >
            <Grid item xs={12}>
            <h1 className={styles.headerRoadmap}> - Bscience Launch </h1>
              <h1 className={styles.headerRoadmap}> - Ambassador Program</h1>
              <br />
                <br />
              <p className={styles.mainText}>
                {" "}
                5% of $BCSI will be allocated
                to our “Ambassadors” through staking. A YFSI Ambassador by
                definition is any person willing to stake a minimum of 50 of
                their YFSI tokens.
              </p>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "0px" }}>
              <p className={styles.pCircle}> 2021 </p>
              <p className={styles.pText}> Q1 </p>
            </Grid>
          </Grid>
          <div className="road_map_dot one" />
          <Grid
            item
            container
            xl={2}
            lg={2}
            md={12}
            sm={12}
            xs={12}
            direction="row"
            style={{ margin: "auto", marginBottom: 100 }}
          >
            <Grid item xs={12} style={{ marginTop: "30px" }}>
              <br />
              <br />
              <h1 className={styles.headerRoadmap}> - BCSI Presale</h1>
              <h1 className={styles.headerRoadmap}> - Liquidity Mining & Staking</h1>
              <p className={styles.mainText}> </p>
            </Grid>
            <Grid item xs={12}>
              <p className={styles.pCircle}> 2021 </p>
              <p className={styles.pText}> Q2 </p>
            </Grid>
          </Grid>
          <div className="road_map_dot two" />
          <Grid
            item
            container
            xl={2}
            lg={2}
            md={12}
            sm={12}
            xs={12}
            direction="row"
            style={{ margin: "auto", marginBottom: 220 }}
          >
            <Grid item xs={12}>
              <br />
              <br /> <br />
              <br />
              <h1 className={styles.headerRoadmap}> - Governance</h1>
              <h1 className={styles.headerRoadmap}> - NFT Marketplace</h1>
              <p className={styles.mainText}> </p>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "10px" }}>
              <p className={styles.pCircle}> 2021 </p>
              <p className={styles.pText}> Q2 </p>
            </Grid>
          </Grid>
          <div className="road_map_dot three" />
          <Grid
            item
            container
            xl={2}
            lg={2}
            md={12}
            sm={12}
            xs={12}
            direction="row"
            style={{ margin: "auto", marginBottom: 150 }}
          >
            <Grid item xs={12} style={{ marginTop: "0px" }}>
            <h1 className={styles.headerRoadmap}> - Bridge</h1>
            <h1 className={styles.headerRoadmap}> - NFT Launchpad & NFT Mining</h1>
              <p className={styles.mainText}>
                {" "}
                <br />
                <br />
              </p>
            </Grid>
            <Grid item xs={12}>
              <p className={styles.pCircle}> 2021 </p>
              <p className={styles.pText}> Q3 </p>
            </Grid>
          </Grid>
          <div className="road_map_dot four" />
          <Grid
            item
            container
            xl={2}
            lg={2}
            md={12}
            sm={12}
            xs={12}
            direction="row"
            style={{ margin: "auto", marginBottom: 100 }}
          >
            <Grid item xs={12}>
              <h1 className={styles.headerRoadmap}>
                {" "}
                - Automated Trading Tool
              </h1>
              <p className={styles.mainText}> </p>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "120px" }}>
              <p className={styles.pCircle}> 2021 </p>
              <p className={styles.pText}> Q4 </p>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
}

export default Roadmap;
