import React from "react";
import styles from "./center.module.scss";
import { Grid } from "@material-ui/core";
// import ringImage from "./../../Assets/HeroSectionImage.svg";
import ringImage from "./../../Assets/hearo.png";
import Discord from "../../Assets/discord.png";
import Paper from "../../Assets/paper.png";
import M from "../../Assets/M.png";
import Twitter from "../../Assets/twitter.png";
import MobileImg from "../../Assets/Mobilelogo.png";
import styles2 from '../ready/ready.module.scss'

import { FiChevronRight } from "react-icons/fi";

function Center() {
  return (
    <Grid container direction="row" className="header" xs={12}>
      <Grid
        item
        container
        justify="center"
        xl={8}
        lg={8}
        md={8}
        sm={12}
        xs={12}
        style={{ zIndex: 9999 }}
      >
        <Grid
          className="removeonWeb pos-relative"
          item
          container
          justify="center"
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <img
            src={MobileImg}
            className="removeonWeb"
            style={{
              position: "aboslute",
              top: 0,
              right: "-10%",
              width: "130%",
              marginLeft: -50,
              marginTop: 100,
            }}
          />
        </Grid>
        <Grid item xs={11} md={8}>
          {/* <p className="displaynonemobile">
            <p className={styles.h1Center}> Bscience</p>
          </p> */}
          <p className="displaynonemobile"></p>
          <p className={styles.h3Center}>
            {" "}
            The science of Decentralized Finance{" "}
          </p>

          <p className='lorem' >
          Bscience is a unique platform that combines various DeFi features within one simple ecosystem on Binance Smart Chain. 
          These features give financial control back to the people to store and grow wealth together. Let's advance DeFi on the Binance Smart Chain together!
          </p>
          <p className={styles.h2Center}> YFScience on BSC!</p>
        </Grid>

        <Grid
          item
          xs={11}
          md={8}
          style={{ marginTop: "30px", textAlign: "left" }}
        >
          <a             
          href="https://t.me/financeBSCI"
            type="submit"
            className={styles2.readybutton} style={{ zIndex: 999 }}>

            <span className={styles2.text} > Join us </span> <FiChevronRight />
          </a>
        </Grid>

        <Grid item xs={8} style={{ marginTop: "50px", textAlign: "left" }}>

          <a href="https://t.me/financeBSCI">
            <img src={Paper} className="social-header" />
          </a>
          <a href="https://medium.com/@financeyfsi">
            <img src={M} className="social-header" />
          </a>
          <a href="https://twitter.com/FinanceBSCI">
            <img src={Twitter} className="social-header" />
          </a>
        </Grid>
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={12} xs={12} style={{ zIndex: 99 }}>
        <div className="displaynonemobile">
          {" "}
          <img
            src={ringImage}
            alt="Background Image Rings"
            className="bg-img header_img "
          />
        </div>
      </Grid>

      <div className="flex-center">
        <div className="header-divider" />
      </div>
    </Grid>
  );
}

export default Center;
