import React from "react";
import FarmCard from "./FarmCard";
import { Grid } from "@material-ui/core";
import styles from "./farmmain.module.scss";
import Icon1 from "../../Assets/Icon1.svg";
import Icon2 from "../../Assets/Icon2.svg";
import Icon3 from "../../Assets/Icon3.svg";
import Bg from "../../Assets/wave (9).svg";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { FiChevronRight } from "react-icons/fi";
const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    backgroundColor: "#23436E",
    borderRadius: "15px",
    padding: "30px",
    "&:after": {
      content: "",
      display: "block",
      width: "100%",
      height: "100%",
      transform: `rotate(45deg)`,
      backgroundColor: "#000",
    },
  },
  media: {
    height: 140,
    color: "#FFFFFF",
  },
});

function Farm() {
  const classes = useStyles();
  return (
    <Grid
      container
      xs={12}
      justify="center"
      style={{ backgroundColor: "#10213b" }}
      className="pos-relative farm "
      id="keyfeature"
    >
      <img src={Bg} className="img_bg" />

      <Grid item xs={12} md={6} style={{ zIndex: 999 }}>
        <h1 className={styles.heading}> Key Features</h1>
        <p className={styles.textStyle}>
        Bscience joins Binance’s DeFi Ecosystem and introduces the $BSCI token. 
        We partner with Binance Smart Chain to enable low transaction fees to traders, 
        liquidity miners and providers.
        </p>
      </Grid>
      <Grid item xs={12} style={{ zIndex: 999 }}>
        <Grid container justify="center" style={{ zIndex: 999 }}>
          <Grid xl={4} lg={4} md={4} sm={12} xs={12} className="pos-relative">
            <div className={`farm-cards-overlay`} />
            <Card className={`farm-cards`} style={{ marginTop: "10px" }}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  title="Contemplative Reptile"
                >
                  <img src={Icon1} />
                </CardMedia>
                <CardContent>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Simply deposit one of the LP tokens available and begin earning <b>$BSCI</b>. 
                    Withdraw your tokens and harvest your <b>$BSCI</b> at any time. 
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid xl={4} lg={4} md={4} sm={12} xs={12} className="pos-relative">
            <div className={`farm-cards-overlay`} />
            <Card className={`farm-cards`} style={{ marginTop: "10px" }}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  title="Contemplative Reptile"
                >
                  <img src={Icon2} />
                </CardMedia>
                <CardContent>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    A bridge between Ethereum and Binance Smart Chain will be
                    offered enabling token swapping between the 2 chains.
                    <br />
                    <br />
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid xl={4} lg={4} md={4} sm={12} xs={12} className="pos-relative">
            <div className={`farm-cards-overlay`} />

            <Card className={`farm-cards`} style={{ marginTop: "10px" }}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  title="Contemplative Reptile"
                >
                  <img src={Icon3} />
                </CardMedia>
                <CardContent>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    This project is powered by the YFScience Founders & Ambassadors.  
                    Become a <b>$YFSI</b> Ambassador to receive free <b>$BSCI</b> tokens.  
                    
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Farm;
