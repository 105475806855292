import * as React from "react";
import {
  AppBar,
  Toolbar,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Hidden,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Home } from "@material-ui/icons";
import SideDrawer from "./SideDrawer";
import logo from "./../../Assets/11.svg";
import styles from "./header.module.scss";
import { FiChevronRight } from "react-icons/fi";

const useStyles = makeStyles({
  navbarDisplayFlex: {
    display: `flex`,
    justifyContent: `space-between`,
  },
  navDisplayFlex: {
    display: `flex`,
    justifyContent: `space-between`,
    borderBlockColor: `#64592F`,
    border: `red`,
    marginTop: 40,
  },
  linkText: {
    textDecoration: `none`,
    // textTransform: `uppercase`,
    color: `#F6D869 !important`,
    position: 'relative',

    '&:after': {
      content: '',
      display: 'block',
      width: '100%',
      height: 2,
      position: 'absolute',
      bottom: 0,
      left: 0
    }
  },
  linkTextBtn: {
    textDecoration: `none`,
    // textTransform: `uppercase`,
    color: `#F6D869`,
    borderRadius: 30,
    borderWidth: 1,
    borderColor: "#f6d869",
  },
});

const navLinks = [
  { title: `Key Features`, path: `#keyfeature` },
  { title: `Application`, path: `#Application` },
  { title: `Roadmap`, path: `#Roadmap` },
  { title: `Ambassadors`, path: `#Ambassadors` },
  { title: `Launch App`, path: `https://app.bscience.finance/` }
];

const Header = () => {

  const [transparent, setTransparenr] = React.useState(true);

  const classes = useStyles();

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setTransparenr(false);
    } else {
      setTransparenr(true);
    }
  }

  window.addEventListener("scroll", changeBackground);
  

  return (
    <AppBar  style={{backgroundColor: transparent ? '#0000' : '#132744', zIndex: 99999}} >
      <Toolbar className='topBar'>
        <Container className={classes.navbarDisplayFlex}>
          <IconButton edge="start">
            <img src={logo} alt="Logo" className={styles.logoHeader} />
          </IconButton>
          <Hidden smDown>
            <List
              component="nav"
              aria-labelledby="main navigation"
              className={classes.navDisplayFlex}
            >
              {navLinks.map(({ title, path }) => (
                <>
                  {title === "Launch App" ? (
                    <a href={path} key={title} className={classes.linkText}>
                      <button className='btnCenter'>
                        <span className="text">{title}</span> <FiChevronRight />{" "}
                      </button>
                    </a>
                  ) : (
                    <a href={path} key={title} className='linkText'>
                      <ListItem
                        button
                        // className={title === "Farming" && "linkTextBtn"}
                      >
                        <ListItemText primary={title} />
                        {/* // {title === "Framing" && <FiChevronRight />} */}
                      </ListItem>
                    </a>
                  )}
                </>
              ))}
            </List>
          </Hidden>
          <Hidden mdUp>
            <SideDrawer navLinks={navLinks} />
          </Hidden>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
