import React from "react";
import { Grid } from "@material-ui/core";
import { AiFillTwitterCircle } from "react-icons/ai";
import { FiberManualRecordSharp } from "@material-ui/icons";
import { RiDiscordFill } from "react-icons/ri";
import { IoMdPaperPlane } from "react-icons/io";
import Discord from "../../Assets/discord.png";
import Paper from "../../Assets/paper.png";
import M from "../../Assets/M.png";
import Twitter from "../../Assets/twitter.png";
function Footer() {
  return (
    <div>
      <Grid
        container
        xs={12}
        direction="column"
        justify="center"
        className="footer"
        style={{ backgroundColor: "#0C1A2E", height: "150px" }}
      >
        <Grid item xs={24}>
          {/* <AiFillTwitterCircle color="purple" />
          <RiDiscordFill color="purple" />
          <IoMdPaperPlane color="purple" /> */}
          <a href="https://t.me/financeBSCI">
            <img src={Paper} className="social-header" />
          </a>
          <a href="https://medium.com/@financeyfsi">
            <img src={M} className="social-header" />
          </a>
          <a href="https://twitter.com/FinanceBSCI">
            <img src={Twitter} className="social-header" />
          </a>
        </Grid>

        <div className="flex-center">
          <div className="footer-divider" />
        </div>

        <Grid item xs={24}>
          <p style={{ color: "white" }}> Copyright © Bscience 2021</p>
        </Grid>
      </Grid>
    </div>
  );
}

export default Footer;
