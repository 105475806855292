import React from "react";
import { Grid } from "@material-ui/core";
import styles from "./ready.module.scss";
import Bg from "../../Assets/Footer.png";
import Bg2 from "../../Assets/Footerwave.svg";
import { FiChevronRight } from "react-icons/fi";
import styles2 from "../center/center.module.scss";

function Ready() {
  return (
    <Grid
      container
      xs={12}
      justify="center"
      alignItems="center"
      className="ready pos-relative"
      id='Ambassadors'
    >
      <Grid item xl={6} lg={6} md={8} sm={8} xs={10}>
        <img src={Bg} className="bg_img_1" />
        <img src={Bg2} className="bg_img_2" />
        <div className="flex-center" style={{ flexDirection: "column" }}>
          <h1 className={styles.readyh1} style={{ zIndex: 999 }}>
            {" "}
            Become an Ambassador now!{" "}
          </h1>
          <p className={styles.normaltext} style={{ zIndex: 999 }}>
              {" "}
              Bscience has implemented an Ambassador program where any Ambassador that stakes a minimum of 50 YFSI tokens for six months will share 
              in a percentage of all $BSCI tokens.  Ambassadors are being offered huge rewards and unique benefits!
           </p>
          
          <div
          style={{ marginTop: "30px", textAlign: "center",zIndex:999 }}
        >
          <a
            href="https://app.bscience.finance/ambassador"
            type="submit"
            className={[`${styles2.buttonCenter} one`]}
          >
            {" "}
            <span className={styles2.text}> Learn more</span> <FiChevronRight />
          </a>
          <a
            href="https://app.uniswap.org/#/swap?inputCurrency=0x1df6f1bb7454e5e4ba3bca882d3148fbf9b5697a"
            type="submit"
            className={[`${styles2.buttonCenter} three`]}
          >
            {" "}
            <span className={styles2.text}> Buy YFSI </span> <FiChevronRight />
          </a>

        </div>

          
        </div>
      </Grid>
    </Grid>
  );
}

export default Ready;
