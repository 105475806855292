import React from "react";
import { Grid } from "@material-ui/core";
import styles from "./Application.module.scss";
import AppImg from "../../Assets/Dashboard.svg";
import { FiChevronRight } from "react-icons/fi";

function Application() {
  return (
    <Grid container xs={12} className={styles.ApplicationMain} style={{zIndex: 999}}  id='Application'>
      <Grid item xs={12}>
        <h1 className={styles.heading}> Application</h1>
        <img src={AppImg} className={styles.imageApp} />
          <a href='https://app.bscience.finance/farms' className={styles.buttonCenter}> <span className={styles.text} >Launch App</span> <FiChevronRight /> </a>

        <p className={styles.textStyle}>
        There are many innovative solutions that we are building. Staking, Liquidity Mining, 
        an Eth - Bsc Bridge and an NFT Launchpad are just some of them. 
        All of them will co-exist within one simple user friendly application.
          <br />
          <br />
          Upon platform launch, 6 different liquidity mining pools will be introduced. 
          Users will be able to start farming in our yield farming application which offers one of the best reward systems. 
        </p>
      </Grid>
      {/* <div className='desktop-only' > */}
      {/* <Grid item container xs={11} direction="row" justify="space-around" className={styles.bodyTImeLine}>
                </Grid> */}
      {/* </div> */}
    </Grid>
  );
}

export default Application;
